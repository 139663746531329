
const texts = {
    title: 'Filtrar por',
    inputFilter: {
        nombreFir:'Nombre del Firmante',
        apellidoFir:'Apellido del Firmante',
        cuitFir:'CUIL del Firmante',
        emailFir: 'Email del Firmante',
        estadoDoc:'Estado del Documento',
        estadoDocOpcion:{
            publicado: 'Publicado',
            pendiente: 'Pendiente',
            cerrado: 'Cerrado',
            rechazado: 'Vencido o rechazado',
        },
        entreFechasDesde:'Desde',
        fechaInDes:'Fecha de inicio desde',
        entreFechas:'Hasta',
        fechaInHas:'Fecha de inicio hasta',
        tipFirma:'Tipo de Firma',
        tituloDoc:'Título Documento',
        nombrePublicador: 'Nombre Publicador',
    },
    errorMessages: {
      rechazo:'EF003 : No se puede completar la operación requerida. Intente nuevamente en unos minutos. Si el problema persiste contacte a soporte.',
      networkError: 'EF001 : Se produjo un error en la conexión al servidor. Intente nuevamente en unos minutos.',
      errorFiltrar: 'EF0021 : Se produjo un error con los filtros seleccionados. Vuelva a intentar la operación. De persistir el error contacte a soporte.',
      peticionTardo:'EF002 : El servidor no responde a su solicitud. Intente nuevamente en unos minutos. Si el problema persiste contacte a soporte.',
    },
    buttons: {
      clean: 'Limpiar',
    },
  };
  
  export default texts;
  