import JSEncrypt from 'jsencrypt';
import { useState } from 'react';

const useCrypto = () => {
  const [encryptedData, setEncryptedData] = useState(null);

  const encryptData = async (message, publicKey) => {
    try {
      // Crear un nuevo objeto JSEncrypt
      const jsEncrypt = new JSEncrypt();

      // Establecer la clave pública
      jsEncrypt.setPublicKey(publicKey);

      // Tamaño máximo permitido para cifrar con RSA
      const maxLength = jsEncrypt.getKey().n.bitLength() / 8 - 11;

      // Dividir el mensaje en partes más pequeñas
      const chunks = [];
      for (let i = 0; i < message.length; i += maxLength) {
        chunks.push(message.substring(i, i + maxLength));
      }

      // Cifrar cada parte por separado
      const encryptedChunks = [];
      for (let chunk of chunks) {
        const encryptedChunk = jsEncrypt.encrypt(chunk);
        encryptedChunks.push(encryptedChunk);
      }

      // Concatenar los mensajes cifrados
      const encryptedMessage = encryptedChunks.join('');

      // Establecer el mensaje cifrado en el estado y pasarlo a base64
      setEncryptedData(btoa(encryptedMessage));
    } catch (error) {
      
      console.error("Error al cifrar los datos:", error);
    }
  };
  return { encryptedData, encryptData };
};

export default useCrypto;
