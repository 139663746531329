
const texts = {
  errorMessages: {
    completarEmailDuplicado: 'EF009 : Correo duplicado. No es posible agregar el mismo correo electrónico más de una vez.',
    completarCuitValido: 'EF0010 : CUIL/CUIT Inválido',
    completarCampo: 'EF0011 : Debe completar todos los datos requeridos.',
    completarCuitNoGuiones: 'EF0012 : Debe ingresar CUIL/CUIT sin guiones ni puntos.',
    completarEmailValido: 'EF0013 : Ingrese un correo electrónico válido',
    completarCuitDuplicado: 'EF0014 : CUIL/CUIT duplicado. No es posible agregar dos veces el mismo firmante.',
  },
  input: {
    name: 'Nombre*',
    lastname: 'Apellido*',
    email: 'Correo Electrónico*',
    cuitCuil: 'CUIL*',
  },

};

export default texts;
