import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import PublishDocument from "./PublishDocument";
import PublishedDocuments from "./PublishedDocuments";

const PublisherInterface = ({ keycloak }) => {
  return (
    <Routes>
      <Route
        path="/upload-pdf/*"
        element={
          <PublishDocument
            keycloak={keycloak.token}
          />
        }
      />
       <Route path="/published-documents/*" element={<PublishedDocuments keycloak={keycloak?.token} />} />
      <Route path="/*" element={<Navigate to="/upload-pdf" />} />
    </Routes>
  );
};

export default PublisherInterface;
