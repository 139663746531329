import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import Card from '../components/CardDocuments';
import FilterComponent from '../components/FilterComponent';
import "../components/css/PublishedDocuments.css";
import texts from '../constants/views/publishedDocumentsText';
import publishedDocument from "../img/navbar/uploadFile.svg";
const PublishedDocuments = ({ keycloak }) => {
    const [documentList, setDocumentList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filteredDocumentList, setFilteredDocumentList] = useState([]);
    const [noDocuments, setNoDocuments] = useState(false);
    const [errorMessageConnection, setErrorMessageConnection] = useState("");
    const [isEmailSectionVisible, setIsEmailSectionVisible] = useState(false);

    const handleToggleSection = () => {
        setIsEmailSectionVisible(!isEmailSectionVisible);
    };
    const fetchData = useCallback(async () => {
        try {
            setLoading(true);

            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_GET_DOCS_INFO_PUBLICADOR}`,
                {
                    headers: {
                        Authorization: `Bearer ${keycloak}`,
                        "Access-Control-Allow-Origin": "*",
                    },
                    timeout: 20000,
                }
            );

            const responseData = response.data;
            responseData.sort((a, b) => new Date(a.fecha) - new Date(b.fecha));

            setDocumentList(responseData);
            setFilteredDocumentList(responseData);

            setNoDocuments(responseData.length === 0);

            setErrorMessageConnection("");

        } catch (error) {
            if (axios.isCancel(error)) {
                setErrorMessageConnection(texts.errorMessages.rechazo, error.message);
            } else if (error.code === 'ECONNABORTED') {
                setErrorMessageConnection(texts.errorMessages.peticionTardo);
            } else {
                const errorMsg = error.response?.data?.errorMsg || texts.errorMessages.networkError;
                setErrorMessageConnection(errorMsg);
            }
        } finally {
            setLoading(false);
        }
    }, [keycloak]);

    const handleSearchResults = (results) => {
        setFilteredDocumentList(results);
    };

    const handleFilterResults = (filteredResults) => {
        setFilteredDocumentList(filteredResults);
    };

    useEffect(() => {
        fetchData();
    }, [fetchData]);
    const isMobile = window.innerWidth < 900;
    return (
        <>
            {loading && (
                <Grid container justifyContent="center" alignItems="center">
                    <CircularProgress sx={{ color: "#316094" }} />
                </Grid>
            )}

            {!loading && noDocuments && !errorMessageConnection && (
                <Typography variant="body1" sx={{ margin: "auto", fontFamily: "robotoMedium", padding: "10px" }} gutterBottom>
                    {texts.noTieneDoc}
                </Typography>
            )}

            {!loading && !noDocuments && (
                <>
                    {isEmailSectionVisible ? (
                        <div className="blue-backgroundPublishedDocuments">
                            <div className="rectanglePublishedDocuments">
                                <FilterComponent
                                    documentList={documentList}
                                    onSearchResults={handleSearchResults}
                                    section="PUBLICADO"
                                    estadosDocumento={""}
                                    keycloak={keycloak}
                                    onFilter={handleFilterResults}
                                />
                            </div>
                        </div>
                    ) : null}
                    <div className="iconButtonContainerPublished">
                        <div class="color-bandPublished"></div>
                        <IconButton
                            onClick={handleToggleSection}
                            className="iconButtonPublished"
                        >
                            {isEmailSectionVisible ? (
                                <ArrowBack sx={{ color: "white" }} />
                            ) : (
                                <ArrowForward sx={{ color: "white" }} />
                            )}
                        </IconButton>
                    </div>
                    {/* Contenedor para las tarjetas */}
                    <div className="scroll-containerPublishedDocuments" sx={{
                        justifyContent: isMobile
                            ? filteredDocumentList.length === 1
                                ? 'flex-start !important'
                                : 'center !important'
                            : null,
                    }}>
                        {isMobile ? (<div style={{ display: "flex", width: "90%", alignItems: "center" }}>
                            <img src={publishedDocument} alt="icon" style={{ margin: '0px 10px 0px 0px', width: "30px" }} />
                            <Typography sx={{ width: "90%", textAlign: "left", color: "#316094", fontFamily: "robotoMedium" }}>{texts.title}</Typography>
                        </div>) : null}
                        <Grid container spacing={2} sx={{ backgroundColor: "#F0F0F2", width: "90%", padding: "16px", margin: "40px 0px" }}>
                            {filteredDocumentList.map((documentInfo) => (
                                <Grid item xs={12} sm={filteredDocumentList.length === 1 ? 12 : 6} key={documentInfo.id}>
                                    <div className="DivCardPublishedDocuments">
                                        <Card keycloak={keycloak} documentInfo={documentInfo} section="PUBLICADO" />
                                    </div>
                                </Grid>
                            ))}

                            {/* Mensaje cuando no se encuentran coincidencias */}
                            {filteredDocumentList.length === 0 && !errorMessageConnection && (
                                <Typography variant="body1" sx={{ margin: "auto", fontFamily: "robotoMedium" }} gutterBottom>
                                    {texts.errorMessages.noCoincidencia}
                                </Typography>
                            )}

                            {/* Mensaje de error de conexión */}
                            {!loading && filteredDocumentList.length === 0 && errorMessageConnection && (
                                <Typography variant="body1" sx={{ margin: "auto", color: "red", fontFamily: "robotoMedium" }} gutterBottom>
                                    {errorMessageConnection}
                                </Typography>
                            )}
                        </Grid>
                    </div>
                </>
            )}
        </>
    );
};

export default PublishedDocuments;
