import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Snackbar,
  Typography,
} from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import texts from '../constants/components/cardDocumentsText';
import DocumentToSign from "../views/DocumentToSign";
import PdfViewer from "./PdfViewer";

const Card = ({ documentInfo, keycloak, section }) => {
  const [idDocument, setIdDocument] = useState();
  const [pdfUrl, setPdfUrl] = useState(null);
  const { docName, fecha, nombrePublicador, id, estado, enTransaccion } = documentInfo;

  const [pdfDialog, setPdfDialog] = useState({
    id: null,
    docname: null,
    documento: null,
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const formatFechaFin = (fecha) => {
    try {
      // Asegurar que fecha sea interpretada correctamente como Date
      const dateObject = new Date(fecha);

      // Opciones para el formato de salida
      const options = {
        weekday: "long",
        day: "numeric",
        month: "long",
        year: "numeric",
      };

      // Formatear la fecha usando Intl.DateTimeFormat
      const formattedDate = new Intl.DateTimeFormat("es-ES", options).format(
        dateObject
      );

      // Capitalizar la primera letra del día de la semana
      const capitalizedDate = formattedDate.replace(/^\w/, (c) =>
        c.toUpperCase()
      );
      return capitalizedDate;
    } catch (error) {
      console.error(texts.errorMessages.formatFecha, error.message);
      return texts.errorMessages.formatFecha;
    }
  };

  const getNavigationPathByStatus = useCallback(() => {
    switch (section) {
      case "A_FIRMAR":
        return `/documents-to-sign/${id}`;
      case "EN_TRAMITE":
        return `/documents-in-process/${id}`;
      case "RECHAZADO":
        return `/expired-and-rejected-documents/${id}`;
      case "FIRMADO":
        return `/signed-documents/${id}`;
      case "PUBLICADO":
        return `/published-documents/${id}`;
      default:
        if (section === "EN_TRAMITE") {
          return `/documents-in-process/${id}`;
        } else {
          if (section === "A_FIRMAR") {
            return `/documents-to-sign/${id}`;
          } else {
            if (section === "RECHAZADO") {
              return `/expired-and-rejected-documents/${id}`;
            } else {
              if (section === "FIRMADO") {
                return `/signed-documents/${id}`;
              } else {
                if (section === "PUBLICADO") {
                  return `/published-documents/${id}`;
                }
              }
            }
          }
        };
    }
  }, [id, section]);

  const getMessageAndColor = (fecha) => {
    try {
      const currentDate = new Date();
      const dueDate = new Date(fecha);
      const differenceInDays = Math.floor((dueDate - currentDate) / (1000 * 60 * 60 * 24));

      let message = "";
      let color = "";
      if (differenceInDays > 2 && differenceInDays <= 7) {
        message = texts.getMessageAndColor.pocosDias;
        color = "#FF9015";
      } else if (differenceInDays <= 2) {
        message = texts.getMessageAndColor.urgente;
        color = "#D50057";
      }

      return { message, color };
    } catch (error) {
      console.error(texts.errorMessages.mensajeColor, error.message);
      return { message: "", color: "" };
    }
  };

  const handleApiError = (error) => {
    if (axios.isCancel(error)) {
      setErrorMessage(texts.errorMessages.rechazo);
    } else if (error.code === 'ECONNABORTED') {
      setErrorMessage(texts.errorMessages.peticionTardo);
    } else if (error.response && error.response.data) {
      setErrorMessage(error.response.data.errorMsg || texts.errorMessages.cargarPDF);
    } else {
      console.error(texts.errorMessages.cargarPDF, error.message);
      setErrorMessage(texts.errorMessages.cargarPDF);
    }
    setErrorSnackbarOpen(true);
  };
  
  const handleDetailsClick = useCallback(async () => {
    try {
      setLoading(true);
  
      const headers = { Authorization: `Bearer ${keycloak}`,"Access-Control-Allow-Origin": "*", };
      const url = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_GET_DOC_TO_SIGN}?id=${id}`;
  
      const response = await axios.get(url, { headers, timeout: 20000 });
      const pdfUrlData = response.data;
  
      setPdfUrl(pdfUrlData);
      setPdfDialog((prevState) => ({ ...prevState, docname: pdfUrlData.docName }));
      setOpenDialog(true);
  
      const secondUrl = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_GET_DOC}`;
      const secondResponse = await axios.get(secondUrl, {
        headers,
        params: { id: pdfUrlData.id },
        timeout: 20000,
      });
      const responseData = secondResponse.data.base64;
  
      setIdDocument(pdfUrlData.id);
      setPdfDialog((prevState) => ({
        ...prevState,
        documento: `data:application/pdf;base64,${responseData}`,
        id: pdfUrlData.id,
      }));
  
      navigate(getNavigationPathByStatus());
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  }, [id, keycloak, navigate, getNavigationPathByStatus]);
  
  


  useEffect(() => {
    const loadDocumentFromUrl = async () => {
      const params = new URLSearchParams(window.location.search);
      const idFromUrl = params.get("id");

      if (idFromUrl && idFromUrl === id) {
        try {
          setLoading(true);

          const headers = {
            Authorization: `Bearer ${keycloak}`,
            "Access-Control-Allow-Origin": "*",
          };

          // Primer request: Obtener URL del PDF
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_GET_DOC_TO_SIGN}?id=${id}`,
            { headers, timeout: 20000 }
          );

          const pdfUrlData = response.data;
          setPdfUrl(pdfUrlData);
          setPdfDialog((prevState) => ({
            ...prevState,
            docname: pdfUrlData.docName,
          }));

          setOpenDialog(true);

          try {
            // Segundo request: Obtener PDF en base64
            const docId = pdfUrlData.id;
            const response = await axios.get(
              `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_GET_DOC}`,
              {
                headers,
                params: { id: docId },
                timeout: 20000,
              }
            );

            const responseData = response.data.base64;
            setIdDocument(docId);
            setPdfDialog((prevState) => ({
              ...prevState,
              documento: `data:application/pdf;base64,${responseData}`,
              id: docId,
            }));

            const navigationPath = getNavigationPathByStatus();
            navigate(navigationPath);

            setOpenDialog(true);
          } catch (error) {
            if (axios.isCancel(error)) {
              setErrorMessage(texts.errorMessages.rechazo, error.message);
            } else if (error.code === 'ECONNABORTED') {
              setErrorMessage(texts.errorMessages.peticionTardo);
            } else if (error.response && error.response.data) {
              const errorMsg = error.response.data.errorMsg;
              setErrorMessage(`${errorMsg}`);
            } else {
              console.error(texts.errorMessages.errorPeticion, error.message);
              setErrorMessage(texts.errorMessages.cargarPDF);
            }
            setErrorSnackbarOpen(true);
          } finally {
            setLoading(false); // Finalizar el estado de carga para el segundo request
          }
        } catch (error) {
          if (axios.isCancel(error)) {
            setErrorMessage(texts.errorMessages.rechazo, error.message);
          } else if (error.code === 'ECONNABORTED') {
            setErrorMessage(texts.errorMessages.peticionTardo);
          } else if (error.response && error.response.data) {
            const errorMsg = error.response.data.errorMsg;
            setErrorMessage(`${errorMsg}`);
          } else {
            console.error(texts.errorMessages.errorPeticion, error.message);
            setErrorMessage(texts.errorMessages.cargarPDF);
          }
          setErrorSnackbarOpen(true);
        } finally {
          setLoading(false); // Finalizar el estado de carga para el primer request
        }
      }
    };

    loadDocumentFromUrl();
  }, [id, keycloak, navigate, getNavigationPathByStatus]);




  const handleCloseDialog = () => {
    setOpenDialog(false);
    const navigationPath = getNavigationPathByStatus(section);
    if (navigationPath) {
      const newPath = navigationPath.substring(
        0,
        navigationPath.indexOf("/", navigationPath.indexOf("/") + 1)
      );
      navigate(newPath);
    } else {
      setErrorMessage(texts.errorMessages.invalidNavigation);
      setErrorSnackbarOpen(true);
      window.location.reload();
    }
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorSnackbarOpen(false);
  };

  const { message, color } = getMessageAndColor(fecha);
  const pdfArray = [pdfDialog];
  const isMobile = window.innerWidth < 900;
  return (
    <div className="card-container">
      <div style={{ display: "flex", flexDirection: "column", width: "70%" }}>
        {
          section === "A_FIRMAR" ? (
            <Typography variant="body2" component="p" style={{ fontFamily: "robotoMedium", fontSize: "12px", color: color }}>
              {message}
            </Typography>
          ) : section === "PUBLICADO" ? (
            <>
              <Typography variant="body2" component="p" style={{ fontFamily: "robotoMedium", fontSize: "12px", color: "#221F1C" }}>
                {texts.estadoDoc} <strong style={{}}>{estado} </strong>
              </Typography>
            </>
          ) : null
        }
        {isMobile ? (<Typography variant="h6" sx={{
          fontFamily: "robotoMedium",
          fontSize: "12px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          width: '200px'
        }} component="h2">
          <strong>{docName}</strong>
        </Typography>) : (<Typography
          variant="h6"
          sx={{
            fontFamily: "robotoMedium",
            fontSize: "12px",
            overflow: "hidden",
            textOverflow: "ellipsis", // Asegura que la altura del contenedor se ajuste automáticamente
          }}
          component="h2"
        >
          <strong>{docName}</strong>
        </Typography>)}

        <Typography variant="body2" style={{ fontFamily: "robotoMedium", fontSize: "12px", color: "#221F1C" }} component="p">
          {texts.publicadoPor}{nombrePublicador}
        </Typography>
        {
          section === "FIRMADO" ? (
            <Typography variant="body2" style={{ fontFamily: "robotoMedium", fontSize: "12px", color: "#221F1C" }} component="p">
              {texts.estadoFirma.cerrado} {formatFechaFin(fecha)}
            </Typography>
          ) : section === "RECHAZADO" && estado === "VENCIDO" ? (
            <Typography variant="body2" style={{ fontFamily: "robotoMedium", fontSize: "12px", color: "#221F1C" }} component="p">
              {texts.estadoFirma.vencido} {formatFechaFin(fecha)}
            </Typography>
          ) : section === "RECHAZADO" && estado === "RECHAZADO" ? (
            <Typography variant="body2" style={{ fontFamily: "robotoMedium", fontSize: "12px", color: "#221F1C" }} component="p">
              {texts.estadoFirma.rechazado} {formatFechaFin(fecha)}
            </Typography>
          ) : section === "PUBLICADO" ? (
            estado === "CERRADO" ? (
              <Typography variant="body2" style={{ fontFamily: "robotoMedium", fontSize: "12px", color: "#221F1C" }} component="p">
                {texts.estadoFirma.cerrado} {formatFechaFin(fecha)}
              </Typography>
            ) : estado === "VENCIDO" ? (
              <Typography variant="body2" style={{ fontFamily: "robotoMedium", fontSize: "12px", color: "#221F1C" }} component="p">
                {texts.estadoFirma.vencido} {formatFechaFin(fecha)}
              </Typography>
            ) : estado === "RECHAZADO" ? (
              <Typography variant="body2" style={{ fontFamily: "robotoMedium", fontSize: "12px", color: "#221F1C" }} component="p">
                {texts.estadoFirma.rechazado} {formatFechaFin(fecha)}
              </Typography>
            ) : (
              <Typography variant="body2" style={{ fontFamily: "robotoMedium", fontSize: "12px", color: "#221F1C" }} component="p">
                {texts.estadoFirma.venceEl} {formatFechaFin(fecha)}
              </Typography>
            )
          ) : section === "A_FIRMAR" || section === "EN_TRAMITE" ? (
            <Typography variant="body2" style={{ fontFamily: "robotoMedium", fontSize: "12px", color: "#221F1C" }} component="p">
              {texts.estadoFirma.venceEl} {formatFechaFin(fecha)}
            </Typography>
          ) : (
            <Typography variant="body2" style={{ fontFamily: "robotoMedium", fontSize: "12px", color: "#221F1C" }} component="p">
              {formatFechaFin(fecha)}
            </Typography>
          )
        }
      </div>

      <Button
        style={{
          backgroundColor: "#316094", fontFamily: "robotoMedium",
          fontSize: "10px", marginTop: "8px", whiteSpace: 'nowrap',
          width: '90px'
        }}
        variant="contained"
        className="details-button"
        onClick={handleDetailsClick}
      >
        {texts.buttons.masDetalles}
      </Button>

      {pdfDialog && (
        <Dialog
          PaperProps={{
            sx: {
              width: "100%", maxWidth: "100%", margin: {
                xs: "10px !important",
                md: "32px !important"
              }
            }
          }}
          open={openDialog}
        >
          <DialogTitle sx={{
            color: "#316094", bgcolor: "#F0F0F2", fontFamily: "robotoMedium", display: "flex", alignItems: "center", justifyContent: "space-between", padding: {
              xs: "1px 16px", // Padding para dispositivos móviles (xs)
              md: "16px 24px" // Padding para pantallas de tamaño mediano (md) y mayores
            }
          }}>
            {texts.detallesDoc}
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleCloseDialog}
              aria-label="close"
            >
              <CloseIcon sx={{ color: "#316094" }} />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {loading ? (
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "200px" }}>
                <CircularProgress sx={{ color: "#316094" }} size={64} />
                <p style={{ marginLeft: "16px", fontFamily: "robotoMedium" }}>{texts.cargandoDoc}</p>
              </div>
            ) : enTransaccion === true ? (
              <Typography variant="body2" style={{ fontFamily: "robotoMedium", marginTop: "10px", fontSize: "16px", color: "red" }} component="p">
                {texts.errorMessages.enTransaccion}
              </Typography>
            ) : (
              <div style={{ display: "flex", flexDirection: window.innerWidth <= 768 ? "column" : "row" }}>
                <div style={{ width: isMobile ? "100%" : "63%" }}>
                  <PdfViewer pdf={pdfArray} />
                </div>

                <DocumentToSign documentData={pdfUrl} estado={estado} idDocument={idDocument} section={section} />
              </div>
            )}
          </DialogContent>
        </Dialog>
      )}
      <Snackbar
        open={errorSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert onClose={handleSnackbarClose} severity="error" variant="filled">
          {errorMessage}
        </MuiAlert>
      </Snackbar>
     
    </div>
  );
};

export default Card;
