import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useKeycloak } from "@react-keycloak/web";
import React, { useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import "./App.css";
import Navbar from "./components/Navbar";
import texts from './constants/views/signerInterfaceText';
import Loading from "./views/Loading";
import NotFound from "./views/NotFound";
import PublisherInterface from "./views/PublisherInterface";
import PublisherSignerInterface from "./views/PublisherSignerInterface";
import SignerInterface from "./views/SignerInterface";
const INACTIVITY_TIMEOUT = 5 * 60 * 1000; // 15 minutos
function App() {
  const { keycloak, initialized } = useKeycloak();
  // eslint-disable-next-line no-unused-vars
  const [selectedTab, setSelectedTab] = useState(3);
  const [openCuitDialog, setOpenCuitDialog] = useState(false);
  const [userRoles, setUserRoles] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [userRoleLoaded, setUserRoleLoaded] = useState(false);
  useEffect(() => {
    let inactivityTimer;

    const resetTimer = () => {
      clearTimeout(inactivityTimer);
      inactivityTimer = setTimeout(() => {
        // Limpia el almacenamiento local
        localStorage.clear();

        // Limpia las cookies (esto eliminará todas las cookies, no solo las de Keycloak)
        document.cookie.split(";").forEach((c) => {
          document.cookie = c.replace(/^ +/, "").replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
        });
        keycloak.logout();
      }, INACTIVITY_TIMEOUT);
    };

    window.addEventListener('mousemove', resetTimer);
    window.addEventListener('keypress', resetTimer);
    window.addEventListener('click', resetTimer);
    window.addEventListener('scroll', resetTimer);

    // Inicializar el temporizador al montar el componente
    resetTimer();

    return () => {
      clearTimeout(inactivityTimer);
      window.removeEventListener('mousemove', resetTimer);
      window.removeEventListener('keypress', resetTimer);
      window.removeEventListener('click', resetTimer);
      window.removeEventListener('scroll', resetTimer);
    };
  }, [keycloak]);
  
 
  let userRole;

  if (userRoles.length === 2) {
    userRole = 'firmantepublicador';
  } else {
    userRole = userRoles[0];
  }
  useEffect(() => {
    if (keycloak.authenticated && keycloak.tokenParsed) {
      const roles =
        keycloak.tokenParsed.resource_access?.[process.env.REACT_APP_KEYCLOAK_CLIENT_ID]?.roles || [];
      setUserRoles(roles);
      setUserRoleLoaded(true);
      const cuit = keycloak.tokenParsed.cuil;
     
      if (!cuit && (userRoles.length === 2 || userRole === "firmante")) {
        setOpenCuitDialog(true); // Mostrar el diálogo si el CUIT está vacío
      }
    }
  }, [keycloak.authenticated, keycloak.tokenParsed,userRole,userRoles.length]);
  const handleTabChange = (tabIndex) => {
    setSelectedTab(tabIndex);
  };
  const handleCloseDialog = () => {
    setOpenCuitDialog(false);
  };
  useEffect(() => {
    if (keycloak && initialized) {
      // Establece el manejador para cuando el token expire
      keycloak.onTokenExpired = () => keycloak.updateToken(600);
    }

    // Limpia el manejador cuando el componente se desmonte
    return () => {
      if (keycloak) keycloak.onTokenExpired = () => {};
    };
  }, [initialized, keycloak]);
  if (!initialized || !userRoleLoaded) {
    return <Loading />;
  }

  if (!keycloak.authenticated) {
    keycloak.login();
    return null;
  }


  let mainInterface;

  if (userRole === "firmante" || userRoles.length === 0) {
    mainInterface = <SignerInterface keycloak={keycloak} cuil={keycloak.tokenParsed.cuil} />;
  } else if (userRole === "publicador" || userRoles.length === 0) {
    mainInterface = <PublisherInterface keycloak={keycloak} />;
  }

  else if (userRole === "firmantepublicador") {
    mainInterface = <PublisherSignerInterface keycloak={keycloak} cuil={keycloak.tokenParsed.cuil} />;
  } else {
    mainInterface = <NotFound />;
  }

  return (
    <div className="App">
      <Dialog open={openCuitDialog} onClose={handleCloseDialog}>
        <DialogTitle sx={{ fontFamily: "robotoMedium" }}>CUIL no encontrado</DialogTitle>
        <DialogContent sx={{ fontFamily: "robotoMedium" }}>
        {texts.noCuil}
        </DialogContent>
        <DialogActions>
          <Button sx={{ fontFamily: "robotoMedium" }} onClick={handleCloseDialog} color="primary">
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
      <Router>
        <Navbar onTabChange={handleTabChange} userRole={userRole} cuil={keycloak.tokenParsed.cuil}/>
        <div className="BodyMain" >
          <Routes>
            <Route path="/*" element={mainInterface} />
          </Routes>
        </div>
      </Router>
    </div>

  );
}

export default App;

