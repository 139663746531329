import Box from "@mui/material/Box";
import React, { Fragment } from "react";
export default function Checkbox(props) {
  const { onChange, data: { id, description, cuit, tipoFirma, name, lastName, done } } = props;

  return (
    <Fragment>
      <label style={{ display: "flex", alignItems: "center" }}>
        <input
          name={id}
          type="checkbox"
          defaultChecked={done}
          style={{ display: 'none' }}
          onChange={(e) => onChange(e, id)}
        />
        <Box
          sx={{
            borderBottom: "1px solid #316094",
            display: "flex",
            flexDirection: "column",
            gap:0.5,
          }}
        >
          {/* Nombre + Apellido y CUIT en la misma línea */}
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
              alignItems: { xs: "flex-start", sm: "center" }, // Mejor alineación en móviles
              fontFamily: "robotoMedium",
              fontSize: "12px",
              textAlign: "left",
            }}
          >
            <Box sx={{textAlign: "left",}}>{name} {lastName}</Box>
          </Box>
          {/* Cuil / Cuit */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start", // Ajuste en pantallas pequeñas
              fontFamily: "robotoMedium",
              fontSize: "12px",
            }}
          >
            <Box sx={{
              fontFamily: "robotoMedium",
              fontSize: "12px", color: "white"
            }}>CUIL {cuit}</Box>
          </Box>
          {/* Firma y Estado del documento */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start", // Ajuste en pantallas pequeñas
              fontFamily: "robotoMedium",
              fontSize: "12px",
            }}
          >
            <Box>{description}</Box>
          </Box>

          {/* Tipo de firma */}
          <Box sx={{ fontFamily: "robotoMedium", fontSize: "12px", textAlign: "left" }}>
            Firma  {tipoFirma === "1" ? "Electrónica" : "Digital"}
          </Box>
        </Box>
      </label>
    </Fragment>
  );
}
