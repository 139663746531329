import MuiAlert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import subirArchivo from "../img/subirArchivo.svg";
import "./css/DragDropCard.css";

export default function DragDropCard(props) {
  const { handleAddItem } = props;
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [fileRejectionItems, setFileRejectionItems] = useState([]);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          const base64 = reader.result;
          handleAddItem({
            id: (+new Date()).toString(),
            docname: file.name,
            documento: base64,
            fecha: file.lastModifiedDate,
          });
        };
      });
    },
    [handleAddItem]
  );

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop,
    accept: { "application/pdf": [] },
    maxFiles: 1,
  });

  useEffect(() => {
    const items = fileRejections.map(({ file, errors }) => (
      <li key={file.path}>
        {errors.map((e) => (
          <li key={e.code} className="error-message">
            Demasiados PDFs, solo se permite 1
          </li>
        ))}
      </li>
    ));
    setFileRejectionItems(items);
    setOpenSnackbar(items.length > 0);
  }, [fileRejections]);

  return (
    <>
      {fileRejectionItems.length !== 0 && (
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <MuiAlert onClose={handleCloseSnackbar} severity="error" sx={{
            fontFamily: "robotoMedium",
            fontSize: "12px", width: '100%'
          }}>
            Error, solo se puede agregar un archivo PDF
          </MuiAlert>
        </Snackbar>
      )}

      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} accept=".pdf" />
        <Box component="img" className="upload-image" src={subirArchivo} alt="Subir Archivo" sx={{
          width: {
            sm: '45%',
            md: '15%',
          }
        }} />
        <button className="upload-button">Subir</button>
        <p className="pDragDrop">Seleccione o arrastre el documento a firmar</p>
      </div>
    </>
  );
}
