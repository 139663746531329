import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { searchPlugin } from "@react-pdf-viewer/search";
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import React, { useState } from "react";
import texts from '../constants/components/pdfViewerText.js';
import "./css/PdfViewer.css";

export default function PdfViewer(props) {
  const { pdf } = props;
  const searchPluginInstance = searchPlugin();
  const [error, setError] = useState(null);
  const toolbarPluginInstance = toolbarPlugin();
  const { Toolbar } = toolbarPluginInstance;

  const handleViewerError = (err) => {
    setError(err);
  };
  const handleLoadSuccess = (pdfDocument) => {
  console.log('PDF loaded with number of pages:', pdfDocument.numPages);
};
  const isMobile = window.innerWidth < 900;
  return (
    <div className="pdfContainer">
      {!isMobile && pdf[0] && (
        <Worker
          workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}
        >
          <div className="viewerAndToolbarContainer">
            <div className="viewerContainer">
              <div className="textPdfViewer">
                {pdf[0].docname.split(".pdf")}
              </div>
              <Viewer
                fileUrl={pdf[0].documento}
                plugins={[toolbarPluginInstance, searchPluginInstance]}
                onError={handleViewerError}
                defaultScale={1}
              />
            </div>
            <div className="toolbarContainer">
              <Toolbar>
                {(props) => {
                  const {
                    CurrentPageInput,
                    GoToNextPage,
                    GoToPreviousPage,
                    NumberOfPages,
                    /* ShowSearchPopover, */
                    ZoomIn,
                    ZoomOut,
                  } = props;
                  return (
                    <>
                      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <div className="currentPageInput">
                          <CurrentPageInput />
                        </div>
                        <div className="itemsToolbar">
                          <NumberOfPages />
                        </div>

                        <div className="itemsToolbar">
                          <GoToPreviousPage>
                            {(props) => (
                              <button {...props} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                                <KeyboardArrowLeftIcon sx={{color:"white",transform: "rotate(90deg)"}} />
                              </button>
                            )}
                          </GoToPreviousPage>
                        </div>

                        <div className="itemsToolbar">
                          <GoToNextPage>
                            {(props) => (
                              <button {...props} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                                <KeyboardArrowRightIcon sx={{color:"white",transform: "rotate(90deg)"}} />
                              </button>
                            )}
                          </GoToNextPage>
                        </div>
                        <div className="itemsToolbar">
                          <ZoomOut />
                        </div>
                        <div className="itemsToolbar">
                          <ZoomIn />
                        </div>
                        {/* <div style={{ padding: '0px 2px' }}>
                          <ShowSearchPopover />
                        </div> */}
                      </div>
                    </>
                  );
                }}
              </Toolbar>
            </div>
          </div>
        </Worker>
      )}
      {isMobile && pdf[0] &&
        (<Worker
          workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}
        >
          <div className="viewerAndToolbarContainer">
            <div className="viewerContainer">
              <div className="textPdfViewerMobile">
                {pdf[0].docname.split(".pdf")}
              </div>
              <div style={{ maxHeight: "65vh", overflowY: "auto" }}>
                <Viewer
                  fileUrl={pdf[0].documento}
                  plugins={[toolbarPluginInstance, searchPluginInstance]}
                  onError={handleViewerError}
                  defaultScale={0.5}
                  onLoadSuccess={handleLoadSuccess}
                />
              </div>
              <div className="toolbarContainerMobile">
                <Toolbar>
                  {(props) => {
                    const {
                      CurrentPageLabel,
                      NumberOfPages,
                    } = props;
                    return (
                      <>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                          <div className="currentPageInputMobile">
                            <CurrentPageLabel />
                          </div>
                          <p style={{ fontFamily: "robotoMedium", fontSize: "14px",marginRight: "3px",color:"white"}}>de</p>
                          <div className="itemsToolbar">
                            <NumberOfPages />
                          </div>
                        </div>
                      </>
                    );
                  }}
                </Toolbar>
              </div>
            </div>
          </div>
        </Worker>)
      }

      {error && (
        <div className="errorMessage">
          {texts.errorMessages.errorCargarPdf} {error.message}
        </div>
      )}
    </div>
  );
}
