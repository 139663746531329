const abrirFirmador = (signer_filter, signer_url_api_call, stamp, messageCallback, errorCallback) => {
    let websocketFirmador;

    try {
        websocketFirmador = new WebSocket("ws://localhost:8025/websockets/firmador");

        const params = `filtro:${signer_filter}`;
        const url_fm = `url_docs:${signer_url_api_call}`;
        const stampParam = `img_estampa:${stamp}`;

        websocketFirmador.onopen = () => {
            /* console.log("Conexión WebSocket abierta"); */
            websocketFirmador.send(params);
            websocketFirmador.send(url_fm);
            websocketFirmador.send(stampParam);
        };

        websocketFirmador.onmessage = (message) => {/* 
            console.log("Mensaje recibido: ", message.data); */
            if (messageCallback) messageCallback(message.data);
        };

        websocketFirmador.onclose = (event) => {
           /*  console.log("Conexión WebSocket cerrada"); */
            if (messageCallback) messageCallback("Conexión cerrada");
        };

        websocketFirmador.onerror = (error) => {
           /*  console.error("Error en WebSocket: ", error); */
            if (errorCallback) errorCallback(error);
        };
    } catch (error) {
        /* console.error("Error al abrir la conexión WebSocket: ", error); */
        if (errorCallback) errorCallback(error);
    }
};


export default abrirFirmador;
