
const texts = {
    noTieneDoc: 'Usted no tiene Documentos para Firmar',
    title: 'Documentos A Firmar',
    errorMessages: {
      rechazo:'EF003 : No se puede completar la operación requerida. Intente nuevamente en unos minutos. Si el problema persiste contacte a soporte.',
      networkError: 'EF001 : Se produjo un error en la conexión al servidor. Intente nuevamente en unos minutos.',
      noCoincidencia: 'No se encontraron coincidencias.',
      peticionTardo:'EF002 : El servidor no responde a su solicitud. Intente nuevamente en unos minutos. Si el problema persiste contacte a soporte.',
    },
  };
  
  export default texts;
  