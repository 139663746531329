import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import React, { useEffect, useState } from 'react';
import DragDropCard from "../components/DragDropCard";
import EmailSection from "../components/EmailSection";
import PdfViewer from "../components/PdfViewer";
import "../components/css/PublishDocument.css";
import logoFirma from "../img/navbar/marcaCursor.svg";

const PublisherInterface = ({ keycloak }) => {
    const [initialLoad, setInitialLoad] = useState(true);
    const [pdf, setPdf] = useState([]);
    const isDesktop = window.innerWidth > 900;
    const [isEmailSectionVisible, setIsEmailSectionVisible] = useState(true);

    const handleToggleSection = () => {
        setIsEmailSectionVisible(!isEmailSectionVisible);
    };

    useEffect(() => {
        if (initialLoad && pdf.length === 0) {
            setInitialLoad(false);
        }
    }, [initialLoad, pdf]);

    const handleAddItem = (addItem) => {
        setPdf([...pdf, addItem]);
    };

    const handleResetPdf = () => {
        setPdf([]);
    };


    return (
        <>
            {isDesktop ? (
                pdf.length === 0 ? (
                    <>
                        <div style={{ textAlign: "center", flexDirection: "column", height: "calc(100vh - 123px)", backgroundColor: "#316094", justifyContent: "start", alignItems: "center", borderColor: "#316094", width: "100%", display: "flex", margin: "auto", color: "white" }}>

                            <Box component="img" src={logoFirma} alt="Upload" sx={{
                                maxWidth: {
                                    md: '150px', // Para pantallas medianas
                                    lg: '200px', // Para pantallas grandes
                                    xl: '230px', // Para pantallas extra grandes
                                }, marginTop: "2%"
                            }} />
                            <p style={{
                                fontSize: {
                                    md: '25px', // Para pantallas medianas
                                    lg: '30px', // Para pantallas grandes
                                    xl: '35px', // Para pantallas extra grandes
                                }, fontFamily: "robotoMedium",
                            }}>¡Bienvenidos!</p>
                            <div className="white-rectangle">
                                <DragDropCard handleAddItem={handleAddItem} />
                            </div>
                        </div>
                    </>
                ) : (
                    <Grid style={{ backgroundColor: "#316094", width: "100%", display: "flex", height: "calc(100vh - 123px)" }}>
                        <div className="blue-backgroundPublish" style={{ display: isEmailSectionVisible ? "flex" : "none" }}>
                            <EmailSection pdf={pdf} keycloak={keycloak} />
                        </div>
                        <div style={{ height: "100%", display: "flex", flexDirection: "row", backgroundColor: "#E2E2E5", borderRadius: "0" }}>
                            <div class="color-band"></div>
                            <IconButton
                                onClick={handleToggleSection}
                                className="iconButtonPublish"
                            >
                                {isEmailSectionVisible ? (
                                    <ArrowBack sx={{ color: "white" }} />
                                ) : (
                                    <ArrowForward sx={{ color: "white" }} />
                                )}
                            </IconButton>
                        </div>

                        <Grid className="scroll-containerPublishedContainer">
                            <Grid container spacing={2} sx={{ backgroundColor: "#F0F0F2", width: "90%", padding: "16px", marginTop: "40px" }}>
                                <IconButton onClick={handleResetPdf} style={{ alignSelf: "flex-start" }}>
                                    <ArrowBack />
                                </IconButton>
                                <PdfViewer pdf={pdf} />
                            </Grid>
                        </Grid>
                    </Grid>
                )
            ) : (
                <>
                    {pdf.length === 0 && (
                        <div className="blue-backgroundPublish">
                            <div className="white-rectangle">
                                <DragDropCard handleAddItem={handleAddItem} />
                            </div>
                        </div>
                    )}
                    {pdf.length !== 0 && (
                        <div style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "flex-start", backgroundColor: '#316094' }}>
                            <div style={{ display: isEmailSectionVisible ? "flex" : "none",width: "100%",height: "75vh" }}>
                                <EmailSection pdf={pdf} keycloak={keycloak} />
                            </div>

                            <div style={{ width: "100%", backgroundColor: "#F0F0F2", borderRadius: "0" }}>
                                <div class="color-band"></div>
                                <IconButton
                                    onClick={handleToggleSection}
                                    className="iconButtonPublish"
                                >
                                    {isEmailSectionVisible ? (
                                        <ArrowBack sx={{ color: "white" }} />
                                    ) : (
                                        <ArrowForward sx={{ color: "white" }} />
                                    )}
                                </IconButton>
                            </div>
                            <IconButton onClick={handleResetPdf} style={{ justifyContent: "flex-start", width: "100%", background: "#F0F0F2", borderRadius: "0" }}>
                                <ArrowBack />
                            </IconButton>
                            <PdfViewer pdf={pdf} />
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default PublisherInterface;

