
const texts = {
    errorMessages: {
      rechazo:'EF003 : No se puede completar la operación requerida. Intente nuevamente en unos minutos. Si el problema persiste contacte a soporte.',
      networkError: 'EF001 : Se produjo un error en la conexión al servidor. Intente nuevamente en unos minutos.',
      obtenerEstadoFirma: 'EF0028 : Se produjo un error al verificar el estado de firma. Vuelva a intentar la operación en unos minutos. De persistir el error contacte a soporte.',
      noFirma:'EF0029 : Se produjo un error en el proceso de firma digital. Recuerde que para firmar digitalmente debe tener instalada la aplicación “Firmador Digital PJM”. ',
      obtenerEstadoDoc:'EF0030 : Se produjo un error al verificar el estado del documento. Vuelva a intentar la operación en unos minutos. De persistir el error contacte a soporte.',
      firmador:'EF0031 : Se produjo un error en el proceso de firma digital. Recuerde que para firmar digitalmente debe tener instalada la aplicación “Firmador Digital PJM”. ',
      firmarDigitalmente:'EF0032 : No es posible firmar digitalmente un documento desde un dispositivo móvil o tableta. Debe hacerlo desde una computadora personal con la aplicación “Firmador Digital PJM” instalada.',
      peticionTardo:'EF002 : El servidor no responde a su solicitud. Intente nuevamente en unos minutos. Si el problema persiste contacte a soporte.',
    },
    signStatus:{
      exito: 'Su documento se firmó con éxito',
      vencido: 'Este documento ha vencido.',
      proceso: 'Aún no ha terminado el proceso de firma.',
      firmado: 'Ya has firmado este documento.',
    },
    buttons: {
      denegar: 'Denegar',
      cancel:'Cancelar',
      clean: 'Limpiar',
      save: 'Guardar',
      confirmar: 'Aceptar',
      dowload: 'Descargar',
    },
  };
  
  export default texts;
  