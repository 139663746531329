import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import React from "react";
import texts from '../../constants/components/emailListText';
import Checkbox from "./Checkbox";

export default function EmailList(props) {
  const { list, setList } = props;

  const onChangeStatus = (e) => {
    const { name, checked } = e.target;
    const updateList = list.map((item) => ({
      ...item,
      done: item.id === name ? checked : item.done,
    }));
    setList(updateList);
  };

  const onClickRemoveItem = (id) => {
    const updatedList = list.filter((item) => item.id !== id);
    setList(updatedList);
  };

  const listMapeo = list.map((item) => (
    <Grid container sx={{ display: "flex", flexDirection: "row",alignItems: "flex-end", alignContent: "flex-end", justifyContent: "space-between" }} key={item.id}>
      <Grid item xs={12} sm={11}>
        <Checkbox data={item} onChange={onChangeStatus} />
      </Grid>
      <Grid item xs={12} sm={1} sx={{ display: "flex", justifyContent: "flex-start" }}>
        {window.innerWidth > 600 ? (
          <Button
            onClick={() => onClickRemoveItem(item.id)}
            variant="outlined"
            style={{ margin: 0, border: 0, color: "#ffffff", minWidth: "50px" }}
          >
            <DeleteIcon sx={{ color: "white !important" }} />
          </Button>
        ) : (
          <Button
            onClick={() => onClickRemoveItem(item.id)}
            variant="outlined"
            color="error"
            style={{ margin: 0, border: 0, fontFamily: "robotoMedium", color: "white" }}
            startIcon={<DeleteIcon />}
          >
            {texts.buttons.delete}
          </Button>
        )}
      </Grid>
      <Grid item xs={12} sx={{ width: "100%" }}>
        <hr />
      </Grid>
    </Grid>
  ));

  return (
    <div style={{ color: "white", fontSize: "12px", fontFamily: "robotoMedium", }}>
      <h2 className="firmantesText" style={{ fontSize: "14px", fontFamily: "robotoMedium", }}> {texts.titulo}</h2>
      <p className="firmantesText" style={{ fontSize: "12px", fontFamily: "robotoMedium",margin: "0px 0px 10px" }}>
        {texts.subtitulo}
      </p>
      {list.length ? listMapeo : texts.noFirmantes}
    </div>
  );
}
