
const texts = {
  confirmRejectDialog: {
    aceptar: 'Confirmar',
    estasSeguro: '¿Está seguro de que desea rechazar la firma de este documento?',
    rechazar: 'Cancelar',
  },
  errorMessages: {
    networkError: 'EF001 : Se produjo un error en la conexión al servidor. Intente nuevamente en unos minutos.',
    errorNoCoincideFirmante: 'EF0022 : El firmante no coincide con el usuario autenticado.',
    obtenerEstadoFirma: 'EF0023 : No se puede obtener el estado de firma. Vuelva a intentar la operación en unos minutos. De persistir el error contacte a soporte.',
    rechazarFirma: 'EF0024 : Se produjo un error al rechazar la firma sobre el documento. Vuelva a intentar la operación en unos minutos. De persistir el error contacte a soporte.',
    noHayUrl: 'EF0025 : Se produjo un error al descargar el documento. Vuelva a intentar la operación en unos minutos. De persistir el error contacte a soporte.',
    obtenerUrl: 'EF0026 : Se produjo un error en el acceso al documento. Vuelva a intentar la operación en unos minutos. De persistir el error contacte a soporte.',
    cambiarEmail: 'EF0027 : Se produjo un error al actualizar el email. Vuelva a intentar en unos minutos. De persistir el error contacte a soporte.',
    peticionTardo: 'EF002 : El servidor no responde a su solicitud. Intente nuevamente en unos minutos. Si el problema persiste contacte a soporte.',
  },
  fechaIn: 'Fecha de inicio:',
  fechaFin: 'Fecha de cierre:',
  fechaPub: 'Fecha de publicación:',
  publicado: 'Publicado por:',
  firmantesTable: {
    title: 'Firmantes',
    nameFirmante: 'Nombre',
    cuitFirmante: 'CUIL',
    emailFirmante: 'Email',
    tipoFirma: 'Tipo de Firma',
    estado: 'Estado',
    cambioEstado: 'Cambio Estado',
    editarEmail: 'Editar Email:',
  },
  qr: 'Escanear código QR desde su dispositivo móvil',
  documentStatus: {
    rechazo: 'Se rechazó este documento.',
    vencido: 'Este documento ha vencido.',
    proceso: 'Aún no ha terminado el proceso de firma.',
    firmado: 'Ya has firmado este documento.',
  },
  buttons: {
    denegar: 'Denegar',
    cancel: 'Cancelar',
    clean: 'Limpiar',
    save: 'Guardar',
    confirmar: 'Firmar',
    dowload: 'Descargar',
  },
};

export default texts;
