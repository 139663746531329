import { ReactKeycloakProvider } from '@react-keycloak/web';
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import keycloak from './Keycloak';
import "./index.css";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  <ReactKeycloakProvider initOptions={{ onLoad: 'login-required', checkLoginIframe: false }} authClient={keycloak} >
    <App />
  </ReactKeycloakProvider>,
  document.getElementById("root")
);

reportWebVitals();
//cambio para que me tome el pipeline