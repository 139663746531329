import SearchIcon from "@mui/icons-material/Search";
import { Button, CircularProgress, FormControl, Grid, InputAdornment, MenuItem, Select, Snackbar, TextField, ThemeProvider, Typography } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import OutlinedInput from '@mui/material/OutlinedInput';
import { createTheme } from '@mui/material/styles';
import axios from 'axios';
import { format, isValid, parseISO } from 'date-fns';
import React, { useCallback, useState } from 'react';
import texts from '../constants/components/filterComponentText';
import "./css/FilterComponent.css";

const theme = createTheme({
  palette: {
    primary: {
      main: '#00FF0000',
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-input': {
            color: 'black',
            '::placeholder': {
              color: 'black',
              opacity: 1,
            },
          },
          '& .MuiInputLabel-root': {
            color: 'black',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'transparent',
            },
            '&:hover fieldset': {
              borderColor: 'transparent',
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-input': {
            color: 'black',
            '::placeholder': {
              color: 'black',
              opacity: 1,
            },
          },
          '& .MuiInputLabel-root': {
            color: 'black',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'transparent',
            },
            '&:hover fieldset': {
              borderColor: 'transparent',
            },
          },
        },
      },
    },
  },
});

const FilterComponent = ({ documentList, onFilter, section, keycloak, onSearchResults, estadosDocumento }) => {
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [filterCriteria, setFilterCriteria] = useState({
    searchTerm: '',
    signerName: '',
    signerLastname: '',
    cuit: '',
    email: '',
    documentStatus: '',
    startDateFrom: '',
    endDateTo: '',
    signatureType: '',
    documentTitle: ''
  });
  const formatDateForBackend = (dateString) => {
    const date = parseISO(dateString);
    if (isValid(date)) {
      return format(date, 'dd/MM/yyyy');
    } else {
      return '';
    }
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === "startDateFrom" || name === "endDateTo") {
      // Actualiza el estado con el valor en el formato yyyy-MM-dd
      setFilterCriteria(prevState => ({
        ...prevState,
        [name]: value
      }));
    } else {
      setFilterCriteria(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };


  const handleSelectChange = (event) => {
    const { name, value } = event.target;
    setFilterCriteria(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const applyFilter = useCallback(async (criteria) => {
    setLoading(true);
    let queryParams = new URLSearchParams();

    if (criteria.signerName) {
      queryParams.append(`nombreFirmante`, criteria.signerName);
    }
    if (criteria.signerLastname) {
      queryParams.append(`apellidoFirmante`, criteria.signerLastname);
    }
    if (criteria.cuit) {
      queryParams.append(`cuitFirmante`, criteria.cuit);
    }
    if (criteria.email) {
      queryParams.append(`mailFirmante`, criteria.email);
    }
    if (criteria.documentStatus) {
      const statuses = criteria.documentStatus.split(',');
      statuses.forEach(status => {
        queryParams.append(`estadosDocumento`, status);
      });
    }
    if (criteria.startDateFrom) {
      const formattedDate = formatDateForBackend(criteria.startDateFrom);
      if (formattedDate) {
        queryParams.append(`fechaDesde`, formattedDate);
      } else {
        setErrorMessage(texts.validacionMensajes.fechaInicio);
        setErrorSnackbarOpen(true);
        return;
      }
    }
    if (criteria.endDateTo) {
      const formattedDate = formatDateForBackend(criteria.endDateTo);
      if (formattedDate) {
        queryParams.append(`fechaHasta`, formattedDate);
      } else {
        console.error("Invalid end date format");
        setErrorMessage(texts.validacionMensajes.fechaFin);
        setErrorSnackbarOpen(true);
        return;
      }
    }
    if (criteria.signatureType) {
      queryParams.append(`tipoFirma`, criteria.signatureType);
    }
    if (criteria.documentTitle) {
      queryParams.append(`nombreDocumento`, criteria.documentTitle);
    }
    if (criteria.namePublisher) {
      queryParams.append(`nombrePublicador`, criteria.namePublisher);
    }

    const endpoint = section === "PUBLICADO" ?
      `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_GET_DOCS_INFO_PUBLICADOR}?` :
      `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_GET_DOCS_INFO}`;

    const queryString = queryParams.toString();
    const url = `${endpoint}${estadosDocumento}${queryString ? `&${queryString}` : ''}`;

    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${keycloak}`,
          "Access-Control-Allow-Origin": "*",
        },
        timeout: 20000,
      });

      onFilter(response.data);
    } catch (error) {
      if (axios.isCancel(error)) {
        setErrorMessage(texts.errorMessages.rechazo, error.message);
      } else if (error.code === 'ECONNABORTED') {
        setErrorMessage(texts.errorMessages.peticionTardo);
        setErrorSnackbarOpen(true);
      } else if (error.response && error.response.data) {
        // Extrae y maneja errCode y errorMsg
        const errorMsg = error.response.data.errorMsg;
        setErrorMessage(`${errorMsg}`);
        setErrorSnackbarOpen(true);
      } else {
        setErrorMessage(texts.errorMessages.errorFiltrar);
        setErrorSnackbarOpen(true);
      }
    } finally {
      setLoading(false);
    }
  }, [keycloak, section, estadosDocumento, onFilter]);
  const clearFilters = useCallback(() => {
    const newCriteria = {
      searchTerm: '',
      signerName: '',
      signerLastname: '',
      cuit: '',
      email: '',
      documentStatus: '',
      startDateFrom: '',
      endDateTo: '',
      signatureType: '',
      documentTitle: '',
      namePublisher: ''
    };
    setFilterCriteria(newCriteria);
    setTimeout(() => {
      applyFilter(newCriteria);
    }, 0); // Temporizador para asegurar que el estado se haya actualizado
  }, [applyFilter]);
  const isMobile = window.innerWidth < 900;
  return (
    <ThemeProvider theme={theme}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12}>
          <Typography variant="h6" sx={{ fontFamily: "robotoMedium", fontSize: "12px", color: "white", textAlign: "left" }}>
            {texts.title}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextField
            InputLabelProps={{ shrink: true }}
            name="signerName"
            placeholder={texts.inputFilter.nombreFir}
            value={filterCriteria.signerName}
            onChange={handleInputChange}
            variant="outlined"
            size="small"
            fullWidth
            InputProps={{
              sx: { paddingLeft: "0 !important", borderRadius: "4px", backgroundColor: "white", color: "black", fontSize: "12px", fontFamily: "robotoMedium", height: isMobile ? "30px" : "40px", "&:hover": { backgroundColor: "white", color: "#316094" } },
              startAdornment: <InputAdornment position="start"></InputAdornment>,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextField
            name="signerLastname"
            placeholder={texts.inputFilter.apellidoFir}
            value={filterCriteria.signerLastname}
            onChange={handleInputChange}
            variant="outlined"
            size="small"
            fullWidth
            InputProps={{
              sx: { paddingLeft: "0 !important", borderRadius: "4px", backgroundColor: "white", fontSize: "12px", fontFamily: "robotoMedium", height: isMobile ? "30px" : "40px", "&:hover": { backgroundColor: "white", color: "#316094" } },
              startAdornment: <InputAdornment position="start"></InputAdornment>,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextField
            name="cuit"
            placeholder={texts.inputFilter.cuitFir}
            value={filterCriteria.cuit}
            onChange={handleInputChange}
            variant="outlined"
            size="small"
            fullWidth
            InputProps={{
              sx: { paddingLeft: "0 !important", borderRadius: "4px", backgroundColor: "white", fontSize: "12px", fontFamily: "robotoMedium", height: isMobile ? "30px" : "40px", "&:hover": { backgroundColor: "white", color: "#316094" } },
              startAdornment: <InputAdornment position="start"></InputAdornment>,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextField
            name="email"
            placeholder={texts.inputFilter.emailFir}
            value={filterCriteria.email}
            onChange={handleInputChange}
            variant="outlined"
            size="small"
            fullWidth
            color="primary"
            InputProps={{
              sx: { paddingLeft: "0 !important", borderRadius: "4px", backgroundColor: "white", fontSize: "12px", fontFamily: "robotoMedium", height: isMobile ? "30px" : "40px", "&:hover": { backgroundColor: "white", color: "#316094" } },
              startAdornment: <InputAdornment position="start"></InputAdornment>,
            }}
          />
        </Grid>
        {section === "PUBLICADO" ? (
          <Grid item xs={12} sm={6} md={12}>
            <FormControl fullWidth>
              <Select
                displayEmpty
                size="small"
                name="documentStatus"
                value={filterCriteria.documentStatus}
                onChange={handleSelectChange}
                input={<OutlinedInput />}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <em>Estado del Documento</em>;
                  }
                  return selected === "1"
                    ? "Publicado"
                    : selected === "0"
                      ? "Pendiente"
                      : selected === "2"
                        ? "Cerrado"
                        : "Vencido o rechazado";
                }}
                inputProps={{
                  sx: { borderRadius: "4px", backgroundColor: "white", fontSize: "12px", fontFamily: "robotoMedium", height: isMobile ? "30px" : "40px", "&:hover": { backgroundColor: "white", color: "#316094" } },
                }}
              >
                <MenuItem disabled value="">
                  <em>{texts.inputFilter.estadoDoc}</em>
                </MenuItem>
                <MenuItem value="1">{texts.inputFilter.estadoDocOpcion.publicado}</MenuItem>
                <MenuItem value="0">{texts.inputFilter.estadoDocOpcion.pendiente}</MenuItem>
                <MenuItem value="2">{texts.inputFilter.estadoDocOpcion.cerrado}</MenuItem>
                <MenuItem value="3,4">{texts.inputFilter.estadoDocOpcion.rechazado}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        ) : null}

        <Grid item xs={12} sm={6} md={6}>
          <Typography variant="h6" sx={{ fontSize: "12px", fontFamily: "robotoMedium", color: "white" }}>
            {texts.inputFilter.entreFechasDesde}
          </Typography>
          <TextField
            name="startDateFrom"
            placeholder={texts.inputFilter.fechaInDes}
            type="date"
            variant="outlined"
            size="small"
            fullWidth
            value={filterCriteria.startDateFrom}
            onChange={handleInputChange}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              sx: { borderRadius: "4px", backgroundColor: "white", color: "black", fontSize: "12px", fontFamily: "robotoMedium", height: isMobile ? "30px" : "40px", "&:hover": { backgroundColor: "white", color: "#316094" } },
              startAdornment: <InputAdornment position="start"></InputAdornment>,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Typography variant="h6" sx={{ fontSize: "12px", fontFamily: "robotoMedium", color: "white" }}>
            {texts.inputFilter.entreFechas}
          </Typography>
          <TextField
            name="endDateTo"
            placeholder={texts.inputFilter.fechaInHas}
            type="date"
            variant="outlined"
            size="small"
            fullWidth
            value={filterCriteria.endDateTo}
            onChange={handleInputChange}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              sx: { borderRadius: "4px", backgroundColor: "white", fontSize: "12px", fontFamily: "robotoMedium", height: isMobile ? "30px" : "40px", "&:hover": { backgroundColor: "white", color: "#316094" } },
              startAdornment: <InputAdornment position="start"></InputAdornment>,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={12}>
          <FormControl fullWidth>
            <Select
              displayEmpty
              size="small"
              name="signatureType"
              value={filterCriteria.signatureType}
              onChange={handleSelectChange}
              input={<OutlinedInput />}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <em>Tipo de Firma</em>;
                }
                return selected === "1" ? "Electrónica" : "Digital";
              }}
              inputProps={{
                sx: { backgroundColor: "white", fontSize: "12px", fontFamily: "robotoMedium", height: isMobile ? "30px" : "40px", "&:hover": { backgroundColor: "white", color: "#316094" } },
              }}
            >
              <MenuItem disabled value="">
                <em>{texts.inputFilter.tipFirma}</em>
              </MenuItem>
              <MenuItem value="1">Electrónica</MenuItem>
              <MenuItem value="0">Digital</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={section !== "PUBLICADO" ? 6 : 12}>
          <TextField
            name="documentTitle"
            placeholder={texts.inputFilter.tituloDoc}
            value={filterCriteria.documentTitle}
            onChange={handleInputChange}
            variant="outlined"
            size="small"
            fullWidth
            InputProps={{
              sx: { paddingLeft: "0 !important", borderRadius: "4px", backgroundColor: "white", fontSize: "12px", fontFamily: "robotoMedium", height: isMobile ? "30px" : "40px", "&:hover": { backgroundColor: "white", color: "#316094" } },
              startAdornment: <InputAdornment position="start"></InputAdornment>,
            }}
          />
        </Grid>
        {section !== "PUBLICADO" ? (<Grid item xs={12} sm={6} md={6}>
          <TextField
            name="namePublisher"
            placeholder={texts.inputFilter.nombrePublicador}
            value={filterCriteria.namePublisher}
            onChange={handleInputChange}
            variant="outlined"
            size="small"
            fullWidth
            InputProps={{
              sx: { paddingLeft: "0 !important", borderRadius: "4px", backgroundColor: "white", fontSize: "12px", fontFamily: "robotoMedium", height: isMobile ? "30px" : "40px", "&:hover": { backgroundColor: "white", color: "#316094" } },
              startAdornment: <InputAdornment position="start"></InputAdornment>,
            }}
          />
        </Grid>
        ) : null}
        {isMobile ? (<Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Button
            sx={{ backgroundColor: "transparent", fontSize: "12px", fontFamily: "robotoMedium", height: isMobile ? "30px" : "40px", borderRadius: "20px", color: "white", "&:hover": { backgroundColor: "transparent", color: "#E2E2E5" } }}
            onClick={clearFilters}
          >
            {texts.buttons.clean}
          </Button>
          <Button color="primary" variant="outlined" onClick={() => applyFilter(filterCriteria)} sx={{ borderColor: "white", backgroundColor: "white", fontSize: "12px", fontFamily: "robotoMedium", height: "40px", borderRadius: "4px", color: "#316094", "&:hover": { backgroundColor: "white", color: "#316094" } }}>
            <SearchIcon style={{ color: "#316094" }} />{loading ? <CircularProgress size={24} style={{ color: "#316094" }} /> : 'Filtrar'}
          </Button>
        </Grid>) : (
          <>
            <Grid item xs={12} md={6}>
              <Button
                sx={{ backgroundColor: "transparent", fontSize: "12px", fontFamily: "robotoMedium", height: isMobile ? "30px" : "40px", width: "100%", borderRadius: "20px", color: "white", "&:hover": { backgroundColor: "transparent", color: "#E2E2E5" } }}
                onClick={clearFilters}
              >
                {texts.buttons.clean}
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button color="primary" variant="outlined" disabled={loading} onClick={() => applyFilter(filterCriteria)} sx={{ borderColor: "white", backgroundColor: "white",cursor: loading ? "not-allowed" : "pointer",  // Cambia el cursor cuando esté deshabilitado
    opacity: loading ? 0.6 : 1 , fontSize: "12px", fontFamily: "robotoMedium", height: "40px", borderRadius: "4px", color: "#316094", "&:hover": { backgroundColor: "white", color: "#316094" } }}>
                <SearchIcon style={{ color: "#316094" }} />{loading ? <CircularProgress size={24} style={{ color: "#316094" }} /> : 'Filtrar'}
              </Button>
            </Grid>
          </>
        )}

      </Grid>
      <Snackbar
        open={errorSnackbarOpen}
        autoHideDuration={6000}
        onClose={() => setErrorSnackbarOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setErrorSnackbarOpen(false)}
          severity="error"
        >
          {errorMessage}
        </MuiAlert>
      </Snackbar>
    </ThemeProvider>
  );
};

export default FilterComponent;
