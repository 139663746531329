// Componente Padre
import React from "react";
import InfoSection from "../components/InfoSection";

export default function DocumentToSign(props) {
  const { documentData, idDocument,section,estado } = props;
  const documentInfo = {
    title: documentData.docName,
    description: documentData.docDescription,
    startDate: documentData.fechaIni,
    endDate: documentData.fechaFin,
    changeDate: documentData.fechaCierre,
    author: documentData.nombrePublicador,
    firmantes: documentData.firmantes,
    idDocument: idDocument,
    fechaPub: documentData.fechaPub,
    section:section,
    estado:estado,
  };

  return (
    <InfoSection {...documentInfo} />
  );
}
