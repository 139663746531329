import MenuIcon from '@mui/icons-material/Menu';
import { Box, Drawer, Grid, IconButton, Paper, Tab, Tabs, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import documentoAFirmarCursor from "../img/navbar/documentoAFirmarCursor.svg";
import documentoAFirmarNormal from "../img/navbar/documentoAFirmarNormal.svg";
import documentoTramiteCursor from "../img/navbar/documentoTramiteCursor.svg";
import documentoTramiteNormal from "../img/navbar/documentoTramiteNormal.svg";
import documentosFirmadosCursor from "../img/navbar/documentosFirmadosCursor.svg";
import documentosFirmadosNormal from "../img/navbar/documentosFirmadosNormal.svg";
import documentosRechazadosCursor from "../img/navbar/documentosRechazadosCursor.svg";
import documentosRechazadosNormal from "../img/navbar/documentosRechazadosNormal.svg";
import logoFirmaCiudadana from "../img/navbar/marcaNormal.svg";
import publicarDocumentoCursor from "../img/navbar/publicarDocumentoCursor.svg";
import publicarDocumentoNormal from "../img/navbar/publicarDocumentoNormal.svg";
import publishedDocument from "../img/navbar/uploadFile.svg";
import publishedDocumentCursor from "../img/navbar/uploadFileWhite.svg";
import "./css/Tabs.css";

export default function CustomizedTabs({ onTabChange, userRole, cuil }) {
  const location = useLocation();
  const navigate = useNavigate();

  // Define tabs for all roles
  const allTabs = [
    { index: 1, label: 'Publicar Documento', value: 'upload-pdf' },
    { index: 2, label: 'Documentos Publicados', value: 'published-documents' },
    { index: 3, label: 'Documentos a Firmar', value: 'documents-to-sign' },
    { index: 4, label: 'Documentos en Trámite', value: 'documents-in-process' },
    { index: 5, label: 'Documentos Firmados', value: 'signed-documents' },
    { index: 6, label: 'Documentos Vencidos y Rechazados', value: 'expired-and-rejected-documents' }
  ];

  // Filter tabs based on user role
  const getTabsForRole = (role) => {
    switch (role) {
      case 'firmantepublicador':
        if (cuil) {
          return allTabs;
        } else {
          return allTabs.filter(tab => tab.value === 'upload-pdf' || tab.value === 'published-documents');
        }
      case 'publicador':
        return allTabs.filter(tab => tab.value === 'upload-pdf' || tab.value === 'published-documents');
      case 'firmante':
        if (cuil) {
          return allTabs.filter(tab => tab.value === 'documents-to-sign' || tab.value === 'documents-in-process' || tab.value === 'signed-documents' || tab.value === 'expired-and-rejected-documents');
        }
        break; 
      default:
        return allTabs.filter(tab => tab.value === 'not-found');
    }
  };

  const tabs = getTabsForRole(userRole);

  // Initialize currentTabValue based on location.pathname
  const getTabValueFromPath = useCallback((pathname) => {
    const matchedTab = tabs.find(tab => pathname.includes(tab.value));
    if (matchedTab) {
      return matchedTab.value;
    }
    // Return default tab based on userRole
    /*  if (userRole === 'firmantepublicador' || userRole === 'publicador') {
       return 'upload-pdf';
     }
     return 'documents-to-sign'; */
  }, [tabs]);

  const [currentTabValue, setCurrentTabValue] = useState(getTabValueFromPath(location.pathname));

  /*   useEffect(() => {
      setCurrentTabValue(getTabValueFromPath(location.pathname));
    }, [location.pathname, getTabValueFromPath]);
  
    useEffect(() => {
      // Sync the tab selection with the current pathname
      setCurrentTabValue(getTabValueFromPath(location.pathname));
    }, [location.pathname, getTabValueFromPath]); */

  const isMobile = window.innerWidth < 900;
  const tabsDesktopNoScroll = window.innerWidth > 1500;

  const images = {
    'documents-to-sign': {
      normal: documentoAFirmarNormal,
      cursor: documentoAFirmarCursor
    },
    'documents-in-process': {
      normal: documentoTramiteNormal,
      cursor: documentoTramiteCursor
    },
    'signed-documents': {
      normal: documentosFirmadosNormal,
      cursor: documentosFirmadosCursor
    },
    'expired-and-rejected-documents': {
      normal: documentosRechazadosNormal,
      cursor: documentosRechazadosCursor
    },
    'upload-pdf': {
      normal: publicarDocumentoNormal,
      cursor: publicarDocumentoCursor
    },
    'published-documents': {
      normal: publishedDocument,
      cursor: publishedDocumentCursor
    }
  };

  const handleTabChange = (newValue) => {
    if (isMobile) {
      setIsMenuOpen(false);
    }
    setCurrentTabValue(newValue);
    onTabChange(newValue);
    navigateTo(newValue);
  };

  const navigateTo = (newValue) => {
    const selectedTab = tabs.find(tab => tab.value === newValue);
    if (selectedTab) {
      navigate(`/${selectedTab.value}`);
    }
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
    <>
      {isMobile ? (
        <>
          <IconButton
            size="large"
            aria-label="menu"
            onClick={toggleMenu}
            style={{ color: "#316094" }}
          >
            <MenuIcon />
          </IconButton>
          <Drawer
            id="tab-menu"
            open={isMenuOpen}
            onClose={() => setIsMenuOpen(false)}
          >
            <Grid container direction="row" spacing={1} sx={{ backgroundColor: '#f0f0f0', padding: '30px', marginTop: "0", height: "100vh", alignContent: userRole === "firmante" ? "center" : "normal" }}>
              {tabs.map(({ index, label, value }) => (
                <Grid item xs={6} key={value}>
                  <Paper
                    elevation={currentTabValue === value ? 4 : 0}
                    sx={{ display: "flex", justifyContent: "center", flexDirection: "column", height: "160px", borderRadius: "0px", backgroundColor: currentTabValue === value ? '#316094' : 'white', color: currentTabValue === value ? 'white' : '#316094', padding: '20px', textAlign: 'center', cursor: 'pointer' }}
                    onClick={() => handleTabChange(value)}
                  >
                    <img src={currentTabValue === value ? images[value].cursor : images[value].normal} alt={label} style={{ width: '50%', margin: '0 auto', maxHeight: '60px' }} />
                    <Typography variant="body1" style={{ marginTop: "10px", fontFamily: "robotoRegular" }}>{label}</Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Drawer>

          <Box className="LogoContainerFirmaMobile ">
            <Typography variant="h6" component="div" className="TitleTypographyNavbar">
              <img src={logoFirmaCiudadana} alt="logoFirmaCiudadana" className="LogoNavbarFirmaCiudadanaMobile" />
            </Typography>
          </Box>
        </>
      ) : (
        <Tabs
          value={currentTabValue}
          variant={tabsDesktopNoScroll ? "wrapped" : "scrollable"}
          scrollButtons="auto"
          onChange={(_, newValue) => handleTabChange(newValue)}
          aria-label="scrollable auto tabs example"
          sx={{
            height: "100%",
            '.MuiTabs-scrollButtons': {
              color: '#316094 !important',
            },
          }}
        >
          {tabs.map(({ index, label, value }) => (
            <Tab
              wrapped
              key={value}
              label={label}
              value={value}
              icon={
                <img
                  src={currentTabValue === value ? images[value].cursor : images[value].normal}
                  alt={label}
                  style={{ maxWidth: "25px", marginRight: "15px" }}
                />
              }
              sx={{
                minHeight: "60px !important",
                textTransform: 'none',
                padding: '6px 16px',
                marginTop: "10px",
                backgroundColor: "#FAFAFA",
                flexDirection: "row",
                color: '#316094',
                fontFamily: 'robotoRegular',
                borderRadius: "20px 20px 0px 0px",
                marginRight: "5px",
                borderBottom: '2px solid transparent ',
                '&:hover': {
                  borderBottomColor: '#316094',
                },
                '&.Mui-selected': {
                  color: '#FAFAFA',
                  backgroundColor: "#316094",
                  borderBottomColor: '#316094',
                  fontWeight: 600,
                },
              }}
            />
          ))}
        </Tabs>
      )}
    </>
  );
}
