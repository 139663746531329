import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import texts from '../constants/views/loadingText';
function Loading() {
    
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh',flexDirection: "column" }}>
        <h3 style={{fontFamily:"robotoMedium"}}>{texts.cargando}</h3>
      <CircularProgress size={64} sx={{ color: "#316094" }}/>
    </div>
  );
}

export default Loading;
