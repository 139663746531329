
const texts = {
    titulo: 'Firmantes a notificar',
    subtitulo: 'Los siguientes contactos serán notificados para firmar el presente documento',
    noFirmantes: 'No hay firmantes',
    buttons: {
      delete: 'Eliminar',
    },
  };
  
  export default texts;
  