import Keycloak from 'keycloak-js';

const keycloakConfig = new Keycloak({
  // Configuración del cliente en Keycloak
  url: process.env.REACT_APP_KEYCLOAK_URL,
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
});

export default keycloakConfig; 
