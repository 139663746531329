
const texts = {
    title: 'Complete datos y agregue firmantes',
    input: {
        tituloDoc:'Título del Documento*',
        descripcion:'Descripción',
        fechaIn:'Fecha de Inicio*',
        fechaFin: 'Fecha de Fin*',
    },
    subtitle: 'Firmantes*',
    errorMessages: {
      rechazo:'EF003 : No se puede completar la operación requerida. Intente nuevamente en unos minutos. Si el problema persiste contacte a soporte.',
      networkError: 'EF001 : Se produjo un error en la conexión al servidor. Intente nuevamente en unos minutos.',
      invalidNavigation: 'EF004 : URL inválida.',
      peticionTardo:'EF002 : El servidor no responde a su solicitud. Intente nuevamente en unos minutos. Si el problema persiste contacte a soporte.',
    },
    validacionMensajes:{
        ingreseTitulo:'EF0015 : Se requiere ingresar un título para el documento.',
        fechaInicio:'EF0016 : Seleccione fecha de inicio para el circuito de firmas.',
        fechaInicioAnterior:'EF0017 : La fecha de inicio no puede ser anterior a la actual.',
        fechaFin: 'EF0018 : Seleccione una fecha de fin para el circuito de firmas.',
        fechaFinAnterior: 'EF0019 : La fecha de fin debe ser posterior a la fecha de inicio.',
        listaVacia: 'EF0020 : Debe agregar al menos un firmante.',
    },
    buttons: {
      send: 'Enviar',
    },
  };
  
  export default texts;
  