import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Dialog,DialogContentText, DialogActions, DialogContent, Grid, InputAdornment, Snackbar, TextField, ThemeProvider, Typography } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from "@mui/material/IconButton";
import { createTheme } from '@mui/material/styles';
import axios from "axios";
import React, { useEffect, useState } from "react";
import texts from '../constants/components/emailSectionText';
import "./css/EmailSection.css";
import EmailAdd from "./emailComponents/EmailAdd";
import EmailList from "./emailComponents/EmailList";

const baseURL = process.env.REACT_APP_BACKEND_URL;
const callbackURL = process.env.REACT_APP_CALLBACK_URL;
const publicarDocumentoEndPoint = process.env.REACT_APP_POST_DOCUMENT;
const documentoUrl = baseURL + publicarDocumentoEndPoint;
const theme = createTheme({
  palette: {
    primary: {
      main: '#00FF0000',
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-input': {
            color: 'black',
            fontSize: "12px",
            '::placeholder': {
              color: 'black',
              opacity: 1,
            },
          },
          '& .MuiInputLabel-root': {
            color: 'black',
            fontSize: "12px",
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'transparent',
            },
            '&:hover fieldset': {
              borderColor: 'transparent',
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-input': {
            color: 'black',
            '::placeholder': {
              color: 'black',
              opacity: 1,
            },
          },
          '& .MuiInputLabel-root': {
            color: 'black',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'transparent',
            },
            '&:hover fieldset': {
              borderColor: 'transparent',
            },
          },
        },
      },
    },
  },
});
const EmailSection = ({ pdf, keycloak }) => {
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [overflowY, setOverflowY] = useState(false);
  const [error, setError] = useState(null);
  const [validationError, setValidationError] = useState("");
  const [addInfo, setAddInfo] = useState({
    docname: "",
    description: "",
    startDate: "",
    endDate: "",
  });
  const [list, setList] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [errors, setErrors] = useState({
    docname: "",
    startDate: "",
    endDate: "",
    list: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [buttonPressed, setButtonPressed] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);

  useEffect(() => {

    if (window.innerWidth <= 1100 || list.length > 0 || (error && error.includes("Por favor,")) || (error && error.includes("No se puede")) || Object.values(errors).some(error => error !== "")) {
      setOverflowY(true);
    } else {
      setOverflowY(false);
    }
  }, [list, errors, error]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));

  };

  const adjustToUTC = (dateString) => {
    const localDate = new Date(dateString);
    const utcDate = new Date(localDate.getTime() + localDate.getTimezoneOffset() * 60000);
    return utcDate;
  };

  const validateFields = () => {
    let isValid = true;
    let errorMessages = "";

    // Validación del título del documento
    if (addInfo.docname.trim() === "") {
      errorMessages += texts.validacionMensajes.ingreseTitulo;
      isValid = false;
    }

    // Validación de la fecha de inicio
    const dateStart = new Date(adjustToUTC(addInfo.startDate));
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    if (addInfo.startDate.trim() === "") {
      errorMessages += texts.validacionMensajes.fechaInicio;
      isValid = false;
    } else if (dateStart.getTime() < currentDate.getTime()) {
      errorMessages += texts.validacionMensajes.fechaInicioAnterior;
      isValid = false;
    }

    // Validación de la fecha de fin
    const dateEnd = new Date(adjustToUTC(addInfo.endDate));

    if (addInfo.endDate.trim() === "") {
      errorMessages += texts.validacionMensajes.fechaFin;
      isValid = false;
    } else if (dateEnd <= dateStart) {
      errorMessages += texts.validacionMensajes.fechaFinAnterior;
      isValid = false;
    }

    // Validación de la lista de firmantes
    if (list.length === 0) {
      errorMessages += texts.validacionMensajes.listaVacia;
      isValid = false;
    }

    // Configura el mensaje de error en el estado de validación
    if (!isValid) {
      setValidationError(errorMessages.trim());
    } else {
      setValidationError("");
    }

    return isValid;
  };


  const handleAddItem = (addItem) => {
    setList([...list, addItem]);
  };

  const formatDate = (date) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit', timeZone: 'UTC' };
    const formattedDate = new Intl.DateTimeFormat('es-ES', options).format(date);
    return formattedDate;
  };

  const createPost = async () => {
    setIsLoading(true);
    setSuccessMessage("");
    setErrorMessage("");
    handleCloseDialogConfirm()
    try {
      const successMessages = [];

      for (const data of pdf) {
        const emailsAndCuits = list.map((item) => ({
          nombre: item.name,
          apellido: item.lastName,
          email: item.description,
          cuit: item.cuit,
          tipoFirma: item.tipoFirma,
        }));
        // eslint-disable-next-line no-unused-vars
        const response = await axios.post(
          documentoUrl,
          {
            docname: addInfo.docname,
            description: addInfo.description,
            documento: data.documento.split(",")[1],
            firmantes: emailsAndCuits,
            fechaIni: formatDate(new Date(adjustToUTC(addInfo.startDate))),
            fechaFin: formatDate(new Date(adjustToUTC(addInfo.endDate))),
            url_callback: callbackURL,
          },
          {
            headers: {
              Authorization: `Bearer ${keycloak}`,
              "Access-Control-Allow-Origin": "*",
            },
          }
        );
        successMessages.push(`Documento ${data.docname} enviado correctamente.`);
        setButtonPressed(true);
        setAddInfo({
          docname: "",
          description: "",
          startDate: "",
          endDate: "",
        });
        setList([]);
        setSuccessMessage(`Documento ${data.docname} enviado correctamente.`);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        setErrorMessage(texts.errorMessages.rechazo, error.message);
      } else if (error.code === 'ECONNABORTED') {
        setErrorMessage(texts.errorMessages.peticionTardo);
      } else if (error.response && error.response.data) {
        const errorMsg = error.response.data.errorMsg;
        setErrorMessage(`${errorMsg}`);
      } else {
        if (error.message === "Network Error") {
          setErrorMessage(texts.errorMessages.networkError);
        } else {
          setErrorMessage(error.message);
        }
      }
    } finally {
      setIsLoading(false);
    }
  };


  const handleEnviarClick = () => {
      createPost();
  };

  const closeMessageSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessMessage("");
    window.location.href = "/published-documents"; // Navega a la página de documentos publicados
  };

  const closeMessageError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage("");
  };
  const handleClickOpenDialogConfirm = () => {
    if (validateFields()) {
      setConfirmDialog(true);
    }
  };
  const handleCloseDialogConfirm = () => {
    setConfirmDialog(false);
  };
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{
        overflowY: overflowY ? "auto" : "hidden",
        scrollbarWidth: overflowY ? "thin" : "none",
        scrollbarColor: overflowY ? "white transparent" : "none",
      }} className="rectangle">
        <Typography variant="h6" sx={{ fontFamily: "robotoMedium", fontSize: "12px", color: "white" }}>
          {texts.title}
        </Typography>
        <TextField
          placeholder={texts.input.tituloDoc}
          variant="outlined"
          id="outlined-size-small"
          name="docname"
          size="small"
          value={addInfo.docname}
          sx={{ height: "30px", backgroundColor: "white", marginBottom: "2%", fontFamily: "robotoMedium", width: '100%', borderRadius: "0" }}
          onChange={handleChange}
          error={!!errors.docname}
          helperText={errors.docname}
          className="custom-textfield"
        />
        <TextField
          placeholder={texts.input.descripcion}
          variant="outlined"
          id="outlined-size-small"
          name="description"
          size="small"
          value={addInfo.description}
          sx={{ height: "30px", backgroundColor: "white", marginBottom: "2%", fontFamily: "robotoMedium", width: '100%', borderRadius: "0" }}
          onChange={handleChange}
        />
        <Grid container spacing={1}>
          <Grid item xs={6} sm={6} md={6}>
            <Typography variant="h6" sx={{ fontSize: "12px", fontFamily: "robotoMedium", color: "white", textAlign: "left" }}>
              {texts.input.fechaIn}
            </Typography>
            <TextField
              type="date"
              variant="outlined"
              id="outlined-size-small"
              sx={{ width: { xs: '100%' }, marginBottom: "2%" }}
              name="startDate"
              value={addInfo.startDate}
              size="small"
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                startAdornment: <InputAdornment position="start"></InputAdornment>,
                style: { height: "30px", backgroundColor: "white", paddingLeft: "0px", fontSize: "12px", fontFamily: "robotoMedium", color: "black", borderRadius: "0"/* , width: '25ch' */ },
              }}
              error={!!errors.startDate}
              helperText={errors.startDate}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <Typography variant="h6" sx={{ fontSize: "12px", fontFamily: "robotoMedium", color: "white", textAlign: "left" }}>
              {texts.input.fechaFin}
            </Typography>
            <TextField
              type="date"
              variant="outlined"
              id="outlined-size-small"
              sx={{ width: { xs: '100%' }, }}
              name="endDate"
              value={addInfo.endDate}
              onChange={handleChange}
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                startAdornment: <InputAdornment position="start"></InputAdornment>,
                style: { height: "30px", backgroundColor: "white", paddingLeft: "0px", marginBottom: "2%", fontSize: "12px", fontFamily: "robotoMedium", color: "black", borderRadius: "0", },
              }}
              error={!!errors.endDate}
              helperText={errors.endDate}
            />
          </Grid>
        </Grid>
        <Typography variant="h6" sx={{ fontFamily: "robotoMedium", fontSize: "12px", color: "white" }}>
          {texts.subtitle}
        </Typography>
        <EmailAdd handleAddItem={handleAddItem} error={error} setError={setError} list={list} />
        <EmailList list={list} setList={setList} />
        <Button
          sx={{
            backgroundColor: "white",
            color: "#316094",
            fontFamily: "robotoMedium",
            fontSize: "12px",
            borderRadius: "4px",
            marginTop: "5px",
            padding: "6px 28px",
            display: buttonPressed || list.length === 0 ? "none" : "block" ,
            "&:hover": {
              backgroundColor: "white",
              color: "#316094",
            },
          }}
          onClick={() => handleClickOpenDialogConfirm()}
          /* onClick={handleEnviarClick} */
          disabled={buttonPressed || list.length === 0 || isLoading}
        >
          {isLoading && (
            <CircularProgress sx={{ color: '#316094' }} size={24} />
          )}
          {isLoading ? '' : texts.buttons.send}
        </Button>
        <Dialog
          open={confirmDialog}
          BackdropProps={{
            sx: {
              backgroundColor: errorMessage ? "transparent" : "rgba(0, 0, 0, 0.5)",
            },
          }}
          sx={{ width: "100%" }}
          PaperProps={{ width: "100%" }}
        >
          <IconButton
            edge="end"
            onClick={handleCloseDialogConfirm}
            sx={{
              display: "flex", justifyContent: "flex-end", width: "100%", boxShadow: "none", // Desactiva la sombra
              "&:hover": {
                backgroundColor: "transparent", // Evita que cambie de color al pasar el mouse
              },
              "&:focus": {
                outline: "none", // Quita el contorno del enfoque
              },
            }}
          >
            <CloseIcon sx={{ color: "gray" }} />
          </IconButton>
          <DialogContent sx={{padding:"0px 24px"}}>
        <DialogContentText sx={{fontFamily: "robotoMedium", color: "#316094" }}>
          Se subirá este documento con los siguientes datos:
        </DialogContentText>
        <Box sx={{ marginTop: "10px" }}>
          <Typography variant="body1" sx={{fontFamily: "robotoMedium"}}>
            <strong styles={{fontFamily: "robotoMedium"}}>Nombre del Documento:</strong> {addInfo.docname}
          </Typography>
          {addInfo.description && (
            <Typography variant="body1" sx={{fontFamily: "robotoMedium"}}>
              <strong styles={{fontFamily: "robotoMedium"}}>Descripción:</strong> {addInfo.description}
            </Typography>
          )}
          <Typography variant="body1" sx={{fontFamily: "robotoMedium"}}>
            <strong styles={{fontFamily: "robotoMedium"}}>Fecha de Inicio:</strong> {addInfo.startDate}
          </Typography>
          <Typography variant="body1" sx={{fontFamily: "robotoMedium"}}>
            <strong styles={{fontFamily: "robotoMedium"}}>Fecha de Fin:</strong> {addInfo.endDate}
          </Typography>
          <Typography variant="body1" sx={{fontFamily: "robotoMedium"}}>
            <strong styles={{fontFamily: "robotoMedium"}}>Firmantes:</strong>
          </Typography>
          {list.length > 0 ? (
    <Box component="ul" sx={{ paddingLeft: 0, listStyle: "none",margin: "9px 0px"
    }}>
      {list.map((signer, index) => (
        <Box
          component="li"
          key={index}
          sx={{
            padding: "10px",
            marginBottom: "10px",
            border: "1px solid #ccc",
            borderRadius: "8px",
            backgroundColor: "#f9f9f9", // Fondo para destacar cada firmante
            fontFamily: "robotoMedium",
          }}
        >
          <Typography variant="body1">
            <strong>Nombre:</strong> {signer.name} {signer.lastName}
          </Typography>
          {signer.description && (
            <Typography variant="body1">
              <strong>Email:</strong> {signer.description}
            </Typography>
          )}
          <Typography variant="body1">
            <strong>CUIT:</strong> {signer.cuit}
          </Typography>
          <Typography variant="body1">
            <strong>Tipo de Firma:</strong> {signer.tipoFirma === "1" ? "Electrónica" : "Digital"}
          </Typography>
        </Box>
      ))}
    </Box>
  ) : (
    <Typography>No hay firmantes asignados.</Typography>
  )}
        </Box>
      </DialogContent>
         <DialogActions>
            <Button sx={{ fontFamily: "robotoMedium", color: "#316094" }} onClick={handleCloseDialogConfirm}>
              Cancelar
            </Button>
            <Button sx={{ fontFamily: "robotoMedium", color: "#316094" }} disabled={isLoading}  onClick={handleEnviarClick}>
              {isLoading ? <CircularProgress size={24} sx={{ color: "#316094" }} /> : "Confirmar"}
            </Button>
          </DialogActions>
        </Dialog>
        {validationError && (
          <Snackbar open={true} autoHideDuration={6000} onClose={() => setValidationError("")}>
            <MuiAlert elevation={6} variant="filled" onClose={() => setValidationError("")} severity="error">
              {validationError}
            </MuiAlert>
          </Snackbar>
        )}

        {errorMessage && (
          <Snackbar open={true} autoHideDuration={6000} onClose={closeMessageError}>
            <MuiAlert elevation={6} sx={{ fontFamily: "robotoMedium", color: "white !important" }} variant="filled" onClose={closeMessageError} severity="error">
              {errorMessage}
            </MuiAlert>
          </Snackbar>
        )}
        {successMessage && (
          <Snackbar open={true} autoHideDuration={6000} onClose={closeMessageSuccess}>
            <MuiAlert elevation={6} variant="filled" sx={{ fontFamily: "robotoMedium", color: "white !important" }} onClose={closeMessageSuccess} severity="success">
              {successMessage}
            </MuiAlert>
          </Snackbar>
        )}
      </Box>
    </ThemeProvider>
  );
};

export default EmailSection;
