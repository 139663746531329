
import { Alert, Snackbar } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import texts from '../constants/views/publisherSignerInterfaceText';
import DocumentsInProcess from "./DocumentsInProcess";
import DocumentsToSign from "./DocumentsToSign";
import ExpiredAndRejectedDocuments from "./ExpiredAndRejectedDocuments";
import PublishDocument from "./PublishDocument";
import PublishedDocuments from "./PublishedDocuments";
import SignedDocuments from "./SignedDocuments";

const PublisherSignerInterface = ({ keycloak, cuil }) => {
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') return; // Evitar que se cierre al hacer click afuera
        setOpenSnackbar(false);
    };
    useEffect(() => {
        if (!cuil) {
            setOpenSnackbar(true); // Mostrar Snackbar si no hay cuil
        }
    }, [cuil]);
    return (
        <>
            <Routes>
                {/* Permitir acceso a 'upload-pdf' y 'published-documents' siempre */}
                <Route
                    path="/upload-pdf/*"
                    element={<PublishDocument keycloak={keycloak?.token} />}
                />
                <Route
                    path="/published-documents/*"
                    element={<PublishedDocuments keycloak={keycloak?.token} />}
                />

                {/* Mostrar solo si hay cuil */}
                {cuil && (
                    <>
                        <Route
                            path="/documents-to-sign/*"
                            element={<DocumentsToSign keycloak={keycloak?.token} />}
                        />
                        <Route
                            path="/documents-in-process/*"
                            element={<DocumentsInProcess keycloak={keycloak?.token} />}
                        />
                        <Route
                            path="/signed-documents/*"
                            element={<SignedDocuments keycloak={keycloak?.token} />}
                        />
                        <Route
                            path="/expired-and-rejected-documents/*"
                            element={<ExpiredAndRejectedDocuments keycloak={keycloak?.token} />}
                        />
                    </>
                )}

                {/* Redirigir a una ruta predeterminada */}
                <Route path="/" element={<Navigate to={cuil ? "/documents-to-sign" : "/published-documents"} />} />
            </Routes>

            {/* Snackbar para mostrar alerta si no hay cuil */}
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackbarClose} severity="warning">
                    {texts.noCuil}
                </Alert>
            </Snackbar>
        </>
    );
};

export default PublisherSignerInterface;
