
const texts = {
    roles:{
        aFirmar:'Solicitar Rol para Firmar',
        aPublicar:'Solicitar Rol para Publicar',
        noRolASolicitar: 'No tienes más roles para solicitar',
        noRol: 'No tienes roles asignados, pedir rol',
    },
    bienvenido:'¡Bienvenido,',
    rolDe:'Rol de',
    buttonOut: 'Salir',
  };
  
  export default texts;
  