
const texts = {
    estadoDoc: 'Estado del documento:',
    publicadoPor: 'Publicado por ',
    estadoFirma: {
        cerrado:'Se terminó de firmar el',
        vencido:'Vencido el',
        rechazado:'Rechazado el',
        venceEl: 'Vence el',
    },
    detallesDoc: 'Detalles del Documento',
    cargandoDoc: 'Cargando documento...',
    errorMessages: {
      rechazo:'EF003 : No se puede completar la operación requerida. Intente nuevamente en unos minutos. Si el problema persiste contacte a soporte.',
      networkError: 'EF001 : Se produjo un error en la conexión al servidor. Intente nuevamente en unos minutos.',
      invalidNavigation: 'EF004 : URL inválida.',
      cargarPDF: 'EF006 : Se produjo un error al procesar el documento PDF.  Intente nuevamente en unos minutos. Si el problema persiste contacte a soporte.',
      errorPeticion: 'EF005 : El servidor no responde a su solicitud. Intente nuevamente en unos minutos. Si el problema persiste contacte a soporte.',
      mensajeColor: 'EF007 : No fue posible determinar si el documento esta próximo a vencer.',
      formatFecha: 'EF008 : Error en el formato de fecha.',
      enTransaccion: 'EF0035 : Otro usuario está trabajando sobre este documento. Por favor vuelva a intentar la operación en un momento. De persistir el error contacte a soporte.',
      peticionTardo:'EF002 : El servidor no responde a su solicitud. Intente nuevamente en unos minutos. Si el problema persiste contacte a soporte.',
    },
    getMessageAndColor:{
        pocosDias:'QUEDAN POCOS DÍAS',
        urgente:'URGENTE: PRÓXIMO A VENCER',
    },
    buttons: {
      masDetalles: 'Más Detalles',
    },
  };
  
  export default texts;
  