import { AppBar, Box, Button, IconButton, Menu, MenuItem, Snackbar, Toolbar, Typography } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import React, { useState } from 'react';
import texts from '../constants/components/navbarText.js';
import logo from "../img/logo.png";
import logoFirmaCiudadana from "../img/navbar/marcaNormal.svg";
import perfilNormal from "../img/navbar/perfilNormal.svg";
import salirSesion from "../img/navbar/salirSesion.svg";
import Tabs from "./Tabs.js";
import "./css/Navbar.css";

export default function MenuAppBar(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [message, setMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const { keycloak } = useKeycloak();
  const handleLogout = () => {
    // Limpia el almacenamiento local
    localStorage.clear();

    // Limpia las cookies (esto eliminará todas las cookies, no solo las de Keycloak)
    document.cookie.split(";").forEach((c) => {
      document.cookie = c.replace(/^ +/, "").replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
    });
    const redirectUri = process.env.REACT_APP_ENV === "prod"
    ? `https://firmaciudadana.pjm.gob.ar`
    : `https://dev-firmaciudadana.pjm.gob.ar`;

    // Llama a Keycloak para cerrar sesión
    keycloak.logout({
      redirectUri: redirectUri
    });
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  let menuItemText;
  let rolText;
  if (props.userRole === "publicador") {
    menuItemText = texts.roles.aFirmar;
    rolText = 'Publicador';
  } else if (props.userRole === "firmante") {
    menuItemText = texts.roles.aPublicar;
    rolText = 'Firmante';
  } else if (props.userRole === "firmantepublicador") {
    menuItemText = texts.roles.noRolASolicitar;
    rolText = 'Publicador y Firmante';
  } else {
    menuItemText = texts.roles.noRol;
    rolText = '';
  }

  /* const handleMenuItemClick = () => {
    Axios.post('tu-url-de-api', { data: 'tu-dato-a-enviar' })
      .then(response => {
        setMessage('Solicitud enviada');
        setOpenSnackbar(true);
      })
      .catch(error => {
        setMessage('Error al enviar la solicitud');
        setOpenSnackbar(true);
        console.error('Error al realizar la solicitud POST:', error);
      });
  }; */

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: "white", height: "123px", display: "flex", alignItems: "center" }}>
      <div className='containerLogoPoderJudicial'>
        <img alt="logoFirmaCiudadana" src={logoFirmaCiudadana} className='logoNavbar' />
        <img alt="Logo" src={logo} className='logoNavbarPoderJudicial' />
      </div>
      <Toolbar sx={{ display: "flex", justifyContent: "space-between", alignItems: 'flex-end', minHeight: "48px !important", padding: "0 !important", backgroundColor: 'white', width: "95%" }} className="ToolbarNavbar">
        <Box className="boxTabs">
          <Tabs onTabChange={props.onTabChange} userRole={props.userRole} cuil={props.cuil}/>
        </Box>
        <IconButton
          sx={{ backgroundColor: "#FAFAFA", borderRadius: " 25px 25px 0 0", marginRight: "20px" }} className="IconButtonNavbar"
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleProfileMenuOpen}
        >

          <img src={perfilNormal} alt="logoPerfil" className="logoPerfil" />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          keepMounted
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          open={Boolean(anchorEl)}
          onClose={handleProfileMenuClose}
        >
          <MenuItem>
            <Typography variant="body1" className="UserBoxBienvenidoTextNavbar">
            {texts.bienvenido} {keycloak.idTokenParsed?.name}!
            </Typography>
          </MenuItem>
          <MenuItem>
            <Typography variant="body1" className="UserBoxNormalTextNavbar">
              {rolText && `${texts.rolDe} ${rolText}`}
            </Typography>
          </MenuItem>
          <MenuItem disabled /* onClick={handleMenuItemClick} */>
            <Typography className="UserBoxNormalTextNavbar" variant="body1">{menuItemText}</Typography>
          </MenuItem>
          <MenuItem className="UserBoxButtonNavbar">
            <Button
              variant="contained"
              className="buttonOutNavbar"
              onClick={handleLogout}
              endIcon={<img src={salirSesion} alt="salirSesion" className="salirSesion" />}
            >
              {texts.buttonOut}
            </Button>
          </MenuItem>
        </Menu>
      </Toolbar>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={message}
      />
    </AppBar>
  );
}
